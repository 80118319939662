import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Container
} from 'reactstrap';
import Link from './link'
import Navigation from 'reactstrap-json-nav'
import navigation from '../data/navigation';
import navigation2 from '../data/navigation2';
import logo from '../assets/images/logo.svg'

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="dark" dark expand="lg" className="py-3">
      <Container>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar className="w-100">
          <Navigation json={navigation} link={Link} className="mr-auto"/>
        </Collapse>
        <Link to="/" className="navbar-brand">
          <img src={logo}/>
        </Link>
        <Collapse isOpen={isOpen} navbar className="w-100">
          <Navigation json={navigation2} link={Link}/>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
