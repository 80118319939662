import React from "react"
import { Container, Row, Col } from 'reactstrap'
import Link from 'gatsby-link'

export default () => (
  <div className="bg-light text-dark py-4">
    <Container>
      <Row className="d-flex align-items-center">
        <Col xs={8}><h2>Become a Raybek Foods Distributor</h2></Col>
        <Col xs={4}>
          <div className="float-right">
            <Link to="distributors" className="btn btn-primary">Find Out More</Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)
