import React from "react"

import { Container } from 'reactstrap'
import BecomeDistributor from './become_distributor'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image'

export default ({title, children, img, distributorCta=true}) => (
  <Layout>
    <SEO title={title}/>
    {img ? <Img fluid={img.childImageSharp.fluid}/> : ''}
    <div className="bg-white text-dark py-5">
      <Container>
        <h1 className="text-center">{title}</h1>
        <hr className="primary"/>
        {children}
      </Container>
    </div>
    {distributorCta ? <BecomeDistributor/> : ''}
  </Layout>
)
