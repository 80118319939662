import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import { FaFacebookSquare, FaInstagram, FaPhone, FaEnvelope } from 'react-icons/fa';
import Link from './link';
import logo from '../assets/images/logo.svg'
import navigation from '../data/navigation';
import navigation2 from '../data/navigation2';
import website from '../data/website';

let SocialLink = ({Icon, link ='/'}) => (
  <Link to={link} className="mr-2">
    <Icon size={30}/>
  </Link>
)

let FooterLink = ({to, children}) => (
  <li>
    <Link to={to}>
      {children}
    </Link>
  </li>
)

let ColumnHeader = ({text}) => (
  <h5 className="mb-3">{text}</h5>
)

export default () => (
  <footer className="py-6">
    <Container>
      <Row>
        <Col xd={12} md={3}>
          <Link to="/">
            <img src={logo} width="80%" alt={website.name} className="mb-3"/>
          </Link>
          <div className="mb-2">
            <FaPhone className="mr-2"/>
            <Link to={`tel:${website.phone}`}>{website.phone}</Link>
          </div>
          <div className="mb-2">
            <FaEnvelope className="mr-2"/>
            <Link to={`mailto:${website.email}`}>{website.email}</Link>
          </div>
          <div className="mb-2 text-secondary">
            Copyright © {new Date().getFullYear()}
            <br/>ABN: 99 823 541 990
          </div>
        </Col>
        <Col xd={12} md={3}>
          <ColumnHeader text="Products"/>
          <ul>
            {navigation.map((item, i) => {
              if (item.url) {
                return <FooterLink key={i} to={item.url}>{item.name}</FooterLink>
              } else {
                return (
                  item.dropdownItems.map((dropdownItem, i) => (
                    <FooterLink key={i} to={dropdownItem.url}>{dropdownItem.name}</FooterLink>
                  ))
                )
              }
            })}
          </ul>
        </Col>
        <Col xd={12} md={3}>
          <ColumnHeader text="Company"/>
          <ul>
            {navigation2.map((item, i) => (
              <FooterLink key={i} to={item.url}>{item.name}</FooterLink>
            ))}
          </ul>
        </Col>
        <Col xd={12} md={3}>
          <ColumnHeader text="Follow Us"/>
          <SocialLink Icon={FaFacebookSquare} link={website.facebook_url}/>
          <SocialLink Icon={FaInstagram} link={website.instagram_url}/>
        </Col>
      </Row>
    </Container>
  </footer>
)
