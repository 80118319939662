import React from 'react'
import {Col} from 'reactstrap'
import Img from 'gatsby-image/withIEPolyfill'

let Sizes = ({sizes}) => (
  <>
    {sizes.join(', ').replace(/, ([^,]*)$/, ' and $1')}
  </>
)

export default ({product}) => (
  <Col xs="6" md="4" className="product-listing">
    <div className="text-center box-hover">
      {product.image && !product.image.localFile ? <img src={product.image} alt={product.name} className="mb-3 json-img"/> : ''}
      {product.image && product.image.localFile ?
        <Img
          fluid={product.image.localFile.childImageSharp.fluid}
          alt={product.name}
          imgStyle={{
            maxWidth: '90%',
            margin: '0 5%'
          }}
          className="mb-3"
          objectFit="contain"
          objectPosition="50% 50%"
        /> : ''}
      <h4>{product.name}</h4>
      <p className="text-secondary">{product.subtitle}</p>
      <p className="text-secondary d-none d-sm-inline">
        {(product.sizes && product.sizes.text) ? product.sizes.text : ''}
        {product.sizes && product.sizes.length && !product.sizes.text ? <Sizes sizes={product.sizes}/> : ''}
      </p>
      <p className="text-secondary d-none d-sm-block">
        {product.ingredients ? product.ingredients : ''}
      </p>
    </div>
  </Col>
)
