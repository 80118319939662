import React from 'react'
import {Row} from 'reactstrap'
import Product from './product-listing'

let NoImages = ({products}) => (
  <div className="d-flex justify-content-center flex-column align-items-center py-4">
    <h5>Other Products/Flavours</h5>
    <hr className="primary"/>
    <ul>
      {products.map((product, i) => (
        <li key={i}>{product.name ? product.name : product.node.data.name}</li>
      ))}
    </ul>
  </div>
)

let JsonProduct = ({products}) => (
  <>
    <Row className="d-flex justify-content-center">
      {products.filter(p => p.image).map((product, i) => (
        <Product product={product} key={i}/>
      ))}
    </Row>
    { products.filter(p => (p.image == null)).length > 0 ?
      <NoImages products={products.filter(p => (p.image == null))}/>
      : ''}
  </>
)

let PrismicProduct = ({edges}) => {
  let noImages = edges.filter(e => e.node.data.image.localFile === null)
  return (
    <>
      <Row className="d-flex justify-content-center">
        {edges.filter(e => e.node.data.image.localFile != null).map((edge, i) => (
          <Product product={edge.node.data}/>
        ))}
      </Row>
      { noImages.length > 0 ? <NoImages products={noImages}/> : ''}
    </>
  )
}

export default ({products}) => (
  <>
    {products.edges ? <PrismicProduct edges={products.edges}/> : <JsonProduct products={products}/>}
  </>
)
